import * as KendoChart from '@progress/kendo-react-charts'
import bodybuilder from 'bodybuilder'
import { HeaderComponent } from 'components/header/component'
import { addDays, format } from 'date-fns'
import {
  AggregateIntervalOptions,
  AggregationType,
  DateRanges, DateRangesAsDropdownOptions,
  FilterType,
  IndexName,
  toFilter
} from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from "react-redux"
import {Fields} from "../../shared/modals";
import {ComponentType} from "react";
import { Dataset } from '../../generic/dataset'
import { PROGRESS_STATUS } from './constants'
import { formatDateForES } from '../../shared_server_client/dates'

interface ProgressThreshold {
  excellent: number,
  good: number,
  needs_work: number,
  okay: number,
  poor: number,
  very_good: number
}

interface Benchmark {
  opens: ProgressThreshold,
  click_through: ProgressThreshold,
  hard_bounce: ProgressThreshold,
  soft_bounce: ProgressThreshold,
  spam_report: ProgressThreshold,
}

interface CommunityConfiguration {
  benchmark: Benchmark,
  score_individual: ProgressThreshold,
  score_global: ProgressThreshold,
}

interface Point {
  spamReport?: number,
  spam?: number
  timestamp: string,
  totalClick: number,
  totalClickRate: number,
  totalDelivered: number,
  totalOpen: number,
  totalOpenRate: number,
  totalBounces?: number,
  uniqueClick: number,
  uniqueClickRate: number,
  uniqueOpen: number,
  uniqueOpenRate: number,
  uniqueClickToOpenRate?: number,
  uniqueBounces?: number,
  uniqueBounce?: number,
  uniqueSoftBounces?: number,
  uniqueSoftBounce?: number
}

type Points = Point

interface EngagementProps extends ElasticsearchProps<Points> {
  dateRange: string,
  hideGrid: boolean,
  threshold: Benchmark
}

interface EngagementState {
  loaded: boolean,
  displayLabel: boolean,
  record: any
}

const INITIALIZED_BENCHMARK: Benchmark = {
  opens: {
    excellent: 40,
    good: 20,
    needs_work: 5,
    okay: 10,
    poor: 5,
    very_good: 30
  },
  click_through: {
    excellent: 3,
    good: 1.5,
    needs_work: 0.5,
    okay: 1,
    poor: 0.5,
    very_good: 2
  },
  hard_bounce: {
    excellent: 1,
    good: 2.5,
    needs_work: 4,
    okay: 3,
    poor: 4.5,
    very_good: 2
  },
  soft_bounce: {
    excellent: 1,
    good: 2.5,
    needs_work: 4,
    okay: 3,
    poor: 4.5,
    very_good: 2
  },
  spam_report: {
    excellent: 0.005,
    very_good: 0.01,
    good: 0.05,
    okay: 0.1,
    needs_work: 0.3,
    poor: 0.3,
  }
}

const INITIALIZED_SCORE_INDIVIDUAL: ProgressThreshold = {
  excellent: 10,
  very_good: 8,
  good: 6,
  okay: 4,
  needs_work: 2,
  poor: 0
}

const INITIALIZED_SCORE_GLOBAL: ProgressThreshold = {
  excellent: 10,
  very_good: 9,
  good: 8,
  okay: 6,
  needs_work: 4,
  poor: 2
}

const initialState = {
  benchmark: INITIALIZED_BENCHMARK,
  scoreIndividual: INITIALIZED_SCORE_INDIVIDUAL,
  scoreGlobal: INITIALIZED_SCORE_GLOBAL,
  selectedDateRange: DateRangesAsDropdownOptions[2],
}

const totalOpenColor = '#004f6d'
const uniqueBouncesColor = '#4ecdc4'
const uniqueSoftBouncesColor = '#ff6b6b'
const totalClickColor = '#8ae0e3'

const EVENT_AGGREGATION: string = 'event'
const DATE_AGGREGATION: string = 'date'

export class EmailHealthComponent extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props, initialState)
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
    this.createCustomDate()
    this.context.user.init().then(({activeCommunity}) => {
      const params = [
        {param: 'key', value: 'email_health_report_threshold'}
      ]
      new Dataset().loadCommunityDataset('communityConfiguration', activeCommunity.communityId, params)
        .then((configuration) => {
          if (configuration.length > 0 && configuration[0].length > 0) {
            const config: CommunityConfiguration = JSON.parse(configuration[0][0].value)
            this.setState({
              benchmark: config.benchmark,
              scoreIndividual: config.score_individual,
              scoreGlobal: config.score_global,
            })
          }
        })
    })
  }

  public createCustomDate = () => {
    const endDate = new Date()
    const startDate = addDays(endDate, -32)

    const customRange = `${formatDateForES(startDate)}|${formatDateForES(addDays(endDate, 1))}`

    this.setState({
      selectedDateRange: {
        ...this.state.selectedDateRange,
        value: customRange,
      },
    })
  }

  public render() {
    return (
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Email Health History'}
          description={[
            'Opens - The number of times your newsletter has been opened by your subscribers.',
            'Clicks - The number of times any of your subscribers have clicked on a link in your newsletter.',
            'Click to Open - the number of clicks out of the number of opens',
          ]}
        />
        <AnalyticsOpensClicksComponent dateRange={this.state.selectedDateRange.value}
                                       threshold={this.state.benchmark}/>
      </div>
    )
  }
}


class OpensClicksClass extends ElasticsearchComponent<Points, EngagementProps, EngagementState> {

  constructor(p: EngagementProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
      displayLabel: true,
      record: []
    }
    this.reportName = Constants.REPORT_NAMES.EMAIL_HEALTH
  }

  public parseResponse(payload: ResponsePayload): Promise<Points> {
    this.setState({
      loaded: true,
    })
    const dateAggregationsBuckets = payload.aggregations[DATE_AGGREGATION].buckets
      .filter((aggregation: ResponseAggregate) => {
        return this.getAggregation(aggregation.child, 'delivered')
      })
    return Promise.resolve(dateAggregationsBuckets.map((aggregation: ResponseAggregate) => {
      const clicks = this.getAggregation(aggregation.child, 'click')
      const opens = this.getAggregation(aggregation.child, 'open')
      const delivered = this.getAggregation(aggregation.child, 'delivered')
      const bounces = this.getAggregation(aggregation.child, 'hard_bounce')
      const unique_soft_bounces = this.getAggregation(aggregation.child, 'soft_bounce')
      const dropped = this.getAggregation(aggregation.child, 'dropped')
      const spamReport = this.getAggregation(aggregation.child, 'spamreport')
      const d = new Date(new Date(aggregation.key))
      const dateWithoutOffset = d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
      const partial: any = {
        timestamp: format(new Date(dateWithoutOffset), 'iii, MMM do'),
        totalClick: clicks ? clicks.doc_count : 0,
        totalDelivered: delivered ? delivered.doc_count : 0,
        totalOpen: opens ? opens.doc_count : 0,
        uniqueClick: clicks && clicks.unique ? clicks.unique.value : 0,
        uniqueOpen: opens && opens.unique ? opens.unique.value : 0,
        uniqueBounces: (bounces && bounces.unique ? bounces.unique.value : 0) +
          (dropped && dropped.unique ? dropped.unique.value : 0),
        uniqueBounce: (bounces && bounces.unique ? bounces.unique.value : 0) +
          (dropped && dropped.unique ? dropped.unique.value : 0),
        uniqueSoftBounces: unique_soft_bounces && unique_soft_bounces.unique ? unique_soft_bounces.unique.value : 0,
        uniqueSoftBounce: unique_soft_bounces && unique_soft_bounces.unique ? unique_soft_bounces.unique.value : 0,
        spamReport: spamReport && spamReport.unique ? spamReport.unique.value : 0,
        spam: spamReport && spamReport.unique ? spamReport.unique.value : 0,
      }

      const totalBounces = partial.uniqueBounce + partial.uniqueSoftBounce + partial.spam
      return {
        ...partial,
        totalClickRate: (
          (partial.totalDelivered > 0 ?
            partial.totalClick / partial.totalDelivered : 0) * 100.0).toFixed(2),
        totalOpenRate: (
          (partial.totalDelivered > 0 ?
            partial.totalOpen / partial.totalDelivered : 0) * 100.0).toFixed(2),
        totalBounces,
        uniqueClickRate: (
          (partial.totalDelivered > 0 ?
            partial.uniqueClick / partial.totalDelivered : 0) * 100.0).toFixed(2),
        uniqueOpenRate: (
          (partial.totalDelivered > 0 ?
            partial.uniqueOpen / partial.totalDelivered : 0) * 100.0).toFixed(2),
        uniqueClickToOpenRate: (
          (partial.uniqueClick > 0 && partial.uniqueOpen > 0 ?
            partial.uniqueClick / partial.uniqueOpen : 0) * 100.0).toFixed(2),
        uniqueBounces: (
          (partial.uniqueBounces > 0 ?
            partial.uniqueBounces / totalBounces : 0) * 100.0).toFixed(2),
        uniqueSoftBounces: (
          (partial.uniqueSoftBounces > 0 ?
            partial.uniqueSoftBounces / totalBounces : 0) * 100.0).toFixed(2),
        spamReport: (
          (partial.spamReport > 0 ?
            partial.spamReport / totalBounces : 0) * 100.0).toFixed(2),
      }
    })).then((dataArray) => {
      this.setState({
        record: dataArray
      })
      const aggregatedData: Points = {
        spamReport: 0,
        spam: 0,
        timestamp: '',
        totalClick: 0,
        totalClickRate: 0,
        totalDelivered: 0,
        totalOpen: 0,
        totalOpenRate: 0,
        totalBounces: 0,
        uniqueClick: 0,
        uniqueClickRate: 0,
        uniqueOpen: 0,
        uniqueOpenRate: 0,
        uniqueBounces: 0,
        uniqueBounce: 0,
        uniqueSoftBounces: 0,
        uniqueSoftBounce: 0
      }

      for (const obj of dataArray) {
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key) && key !== 'timestamp') {
            aggregatedData[key] = (aggregatedData[key] || 0) + parseFloat(obj[key])
          }
        }
      }
      const totalDelivered = aggregatedData.totalDelivered;

      aggregatedData.totalClickRate = Number(
        (totalDelivered > 0 ? (aggregatedData.totalClick / totalDelivered) * 100.0 : 0).toFixed(2)
      )
      aggregatedData.totalOpenRate = Number(
        (totalDelivered > 0 ? (aggregatedData.totalOpen / totalDelivered) * 100.0 : 0).toFixed(2)
      )
      aggregatedData.uniqueClickRate = Number(
        (totalDelivered > 0 ? (aggregatedData.uniqueClick / totalDelivered) * 100.0 : 0).toFixed(2)
      )
      aggregatedData.uniqueOpenRate = Number(
        (totalDelivered > 0 ? (aggregatedData.uniqueOpen / totalDelivered) * 100.0 : 0).toFixed(2)
      )
      aggregatedData.uniqueClickToOpenRate = Number(
        (aggregatedData.uniqueOpen > 0 ? (aggregatedData.uniqueClick / aggregatedData.uniqueOpen) * 100.0 : 0).toFixed(2)
      )
      aggregatedData.uniqueBounces = Number(
        (aggregatedData.uniqueBounce > 0 ? (aggregatedData.uniqueBounce / aggregatedData.totalBounces) * 100.0 : 0).toFixed(2)
      )
      aggregatedData.uniqueSoftBounces = Number(
        (aggregatedData.uniqueSoftBounce > 0 ? (aggregatedData.uniqueSoftBounce / aggregatedData.totalBounces) * 100.0 : 0).toFixed(2)
      )
      aggregatedData.spamReport = Number(
        (aggregatedData.spam > 0 ? (aggregatedData.spam / totalDelivered) * 100.0 : 0).toFixed(2)
      )

      if (dataArray && dataArray.length) {
        const aggregatedPoint: Point = {
          timestamp: dataArray[0].timestamp,
          ...aggregatedData,
        }
        return aggregatedPoint
      }
    })
  }

  public searchPayload(): any {
    const search = bodybuilder().size(0)
      .filter(FilterType.range, 'message_send_date', toFilter(this.props.dateRange || DateRanges.PastMonth))

    return this.addAggregation(search, {
      type: AggregationType.date_histogram,
      field: 'message_send_date',
      extra: {interval: AggregateIntervalOptions[0].value},
      name: DATE_AGGREGATION,
      child: {
        type: AggregationType.terms,
        field: 'event_name.keyword',
        name: EVENT_AGGREGATION,
        unique_on: 'community_person_id',
      },
    }).build()
  }

  public render = () => {
    const totalOpenRate = this.props.results ? this.props.results.totalOpenRate : 0
    const uniqueBounces = this.props.results ? this.props.results.uniqueBounces : 0
    const uniqueSoftBounces = this.props.results ? this.props.results.uniqueSoftBounces : 0
    const totalClicksRate = this.props.results ? this.props.results.totalClickRate : 0
    const totalDelivered = this.props.results ? this.props.results.totalDelivered: 0
    const spamReport = this.props.results ? this.props.results.spamReport : 0
    const opens = this.props.threshold.opens
    const clickThrough = this.props.threshold.click_through
    const hardBounce = this.props.threshold.hard_bounce
    const softBounce = this.props.threshold.soft_bounce
    const spam = this.props.threshold.spam_report
    const score = this.calculateScore()
    const rotation = ((score / 10) * 180) - 180
    return (
      <>
        {this.state.record && this.state.record.length > 0 ?
          <div className='statistics-top-wrapper'>
            <div className="statistic-main-wrap">
              <div className='statistics-chart d-flex flex-column align-items-center'>
                <strong className='statistics-chart-title'>YOUR SCORE</strong>
                <div className={`statistics-chart-round ${score >= 4 ? '--success' : '--warning'}`}
                     style={{'--rotation': `${rotation}deg`} as { [key: string]: string }}>
                  <span className="overlay"></span>
                </div>
                <strong className='statistics-chart-right'>{score}</strong>
                <strong className='statistics-chart-subtitle'>{this.categorizeScore(score)}</strong>
                <strong className='statistics-chart-subtext'>{this.scoreDate()}</strong>
              </div>
              <div className="statistic-summary">
                <div className="summary-description">
                  <strong>Summary</strong>
                  <span>Score over time</span>
                </div>
                <div className="metrics-description">
                  <strong>Metrics</strong>
                  <ul>
                    <li>
                      <span>Opens</span>
                      <div
                        className={`stw-tag-wrapper ${this.opensAndClicksTag(totalOpenRate, opens).class}`}>
                        {this.opensAndClicksTag(totalOpenRate, opens).title}</div>
                    </li>
                    <li>
                      <span>Click-Through</span>
                      <div
                        className={`stw-tag-wrapper ${this.opensAndClicksTag(totalClicksRate, clickThrough).class}`}>
                        {this.opensAndClicksTag(totalClicksRate, clickThrough).title}</div>
                    </li>
                    <li>
                      <span>Hard bounces</span>
                      <div
                        className={`stw-tag-wrapper ${this.bounceTag(uniqueBounces, hardBounce).class}`}>
                        {this.bounceTag(uniqueBounces, hardBounce).title}</div>
                    </li>
                    <li>
                      <span>Soft Bounce</span>
                      <div
                        className={`stw-tag-wrapper ${this.bounceTag(uniqueSoftBounces, softBounce).class}`}>
                        {this.bounceTag(uniqueSoftBounces, softBounce).title}</div>
                    </li>
                    <li>
                      <span>Spam Reports</span>
                      <div
                        className={`stw-tag-wrapper ${this.bounceTag(spamReport, spam).class}`}>
                        {this.bounceTag(spamReport, spam).title}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="list-percent-wrap">
              <ul className='list-unstyled d-flex stw-list text-center'>
                <ProgressComponent
                  title={'OPENS'}
                  heading={`${totalOpenRate}%`}
                  tagTitle={this.opensAndClicksTag(totalOpenRate, opens).title}
                  tagSubtitle={`${this.opensAndClicksTag(totalOpenRate, opens).subtitle}%`}
                  tagClass={this.opensAndClicksTag(totalOpenRate, opens).class}/>
                <ProgressComponent
                  title={'CLICK THROUGH'}
                  heading={`${totalClicksRate}%`}
                  tagTitle={this.opensAndClicksTag(totalClicksRate, clickThrough).title}
                  tagSubtitle={`${this.opensAndClicksTag(totalClicksRate, clickThrough).subtitle}%`}
                  tagClass={this.opensAndClicksTag(totalClicksRate, clickThrough).class}/>
                <ProgressComponent
                  title={'HARD BOUNCES'}
                  heading={`${uniqueBounces}%`}
                  tagTitle={this.bounceTag(uniqueBounces, hardBounce).title}
                  tagSubtitle={`${this.bounceTag(uniqueBounces, hardBounce).subtitle}%`}
                  tagClass={this.bounceTag(uniqueBounces, hardBounce).class}/>
                <ProgressComponent
                  title={'SOFT BOUNCE'}
                  heading={`${uniqueSoftBounces}%`}
                  tagTitle={this.bounceTag(uniqueSoftBounces, softBounce).title}
                  tagSubtitle={`${this.bounceTag(uniqueSoftBounces, softBounce).subtitle}%`}
                  tagClass={this.bounceTag(uniqueSoftBounces, softBounce).class}/>
                <ProgressComponent
                  title={'SPAM REPORTS'}
                  heading={`${spamReport}%`}
                  tagTitle={this.bounceTag(spamReport, spam).title}
                  tagSubtitle={`${this.bounceTag(spamReport, spam).subtitle}%`}
                  tagClass={this.bounceTag(spamReport, spam).class}/>
                <li className='stw-list-item'>
                  <div className='stw-column'>
                    <h2 className='stw-title'>EMAILS SENT</h2>
                    <h3 className='stw-heading'>{totalDelivered}K</h3>
                  </div>
                </li>
              </ul>
              <div className="analytics">
                <div className="engagement-chart">
                  <div>
                    <KendoChart.Chart transitions={false} pannable={false} zoomable={false}>
                      <KendoChart.ChartLegend position="bottom" orientation="horizontal"/>
                      <KendoChart.ChartTooltip
                        render={({points}: KendoChart.SharedTooltipContext) => (
                          <div className="engagement-tooltip">
                            <div>{this.state.record.timestamp || ''}</div>
                            {points.map((point) => (
                              <div key={point.series.name + point.value} style={{color: point.series.color}}>
                                {point.series.name}: {point.value}%
                              </div>
                            ))}
                          </div>
                        )}
                        shared={true}/>
                      <KendoChart.ChartCategoryAxis>
                        {this.state.record.length < Constants.INTERVAL_THRESHOLD &&
                          <KendoChart.ChartCategoryAxisItem
                            categories={this.state.record.map((item) => item.timestamp)}/>}
                      </KendoChart.ChartCategoryAxis>
                      <KendoChart.ChartSeries>
                        <KendoChart.ChartSeriesItem
                          type="line"
                          color={totalOpenColor}
                          field="totalOpenRate"
                          name="Total Open"
                          data={this.state.record}
                        >
                          {this.state.record && this.state.record.length < Constants.INTERVAL_THRESHOLD &&
                            <KendoChart.ChartSeriesLabels format="{0:N2}%"/>}
                        </KendoChart.ChartSeriesItem>
                        <KendoChart.ChartSeriesItem
                          type="line"
                          color={uniqueBouncesColor}
                          field="uniqueBounces"
                          name="Total Unique Bounces"
                          data={this.state.record}
                        >
                          {this.state.record && this.state.record.length < Constants.INTERVAL_THRESHOLD &&
                            <KendoChart.ChartSeriesLabels format="{0:N2}%"/>}
                        </KendoChart.ChartSeriesItem>
                        <KendoChart.ChartSeriesItem
                          type="line"
                          color={uniqueSoftBouncesColor}
                          field="uniqueSoftBounces"
                          name="Total Unique Soft Bounces"
                          data={this.state.record}
                        >
                          {this.state.record && this.state.record.length < Constants.INTERVAL_THRESHOLD &&
                            <KendoChart.ChartSeriesLabels format="{0:N2}%"/>}
                        </KendoChart.ChartSeriesItem>
                        <KendoChart.ChartSeriesItem
                          type="line"
                          color={totalClickColor}
                          field="totalClickRate"
                          name="Total Click"
                          data={this.state.record}
                        >
                          {this.state.record && this.state.record.length < Constants.INTERVAL_THRESHOLD &&
                            <KendoChart.ChartSeriesLabels format="{0:N2}%"/>}
                        </KendoChart.ChartSeriesItem>
                      </KendoChart.ChartSeries>
                    </KendoChart.Chart>
                  </div>
                </div>
              </div>
            </div>
          </div> :
          <div>
            <p className="no-data-tag">{Constants.NO_DATA_COPY}</p>
          </div>}
      </>
    )
  }

  private scoreDate = () => {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit'
    };
    const endDate = new Date()
    const startDate = addDays(endDate, -30)
    const formattedEndDate = endDate.toLocaleDateString('en-US', options);
    const formattedStartDate = startDate.toLocaleDateString('en-US', options);

    return `${formattedStartDate} - ${formattedEndDate}`
  }
  private bounceTag = (number: number, rate: ProgressThreshold) => {
    if (!rate) {
      return {
        title: PROGRESS_STATUS.POOR,
        subtitle: rate.poor,
        class: ''
      };
    }

    const thresholds = [
      {limit: rate.excellent, status: PROGRESS_STATUS.EXCELLENT},
      {limit: rate.very_good, status: PROGRESS_STATUS.VERY_GOOD},
      {limit: rate.good, status: PROGRESS_STATUS.GOOD},
      {limit: rate.okay, status: PROGRESS_STATUS.OKAY},
      {limit: rate.needs_work, status: PROGRESS_STATUS.NEEDS_WORK}
    ];

    const result = thresholds.find(({limit}) => number < limit);

    if (result) {
      return {
        title: result.status,
        subtitle: result.limit,
        class: '--al-success'
      };
    }

    return {
      title: PROGRESS_STATUS.POOR,
      subtitle: rate.poor,
      class: ''
    };
  }

  private calculateScore = () => {
    if (this.props.results) {
      const {results} = this.props
      const opensScore = this.getMetricScore(results.totalOpenRate, this.props.threshold.opens, Constants.MetricScoreOperation.GREATER)
      const clicksScore = this.getMetricScore(results.totalClickRate, this.props.threshold.click_through, Constants.MetricScoreOperation.GREATER)
      const hardBouncesScore = this.getMetricScore(results.uniqueBounces, this.props.threshold.hard_bounce, Constants.MetricScoreOperation.LESS)
      const uniqueSoftBouncesScore = this.getMetricScore(results.uniqueSoftBounces, this.props.threshold.soft_bounce, Constants.MetricScoreOperation.LESS)
      const spamReportScore = this.getMetricScore(results.spamReport, this.props.threshold.spam_report, Constants.MetricScoreOperation.LESS)

      const scoreSum = opensScore + clicksScore + hardBouncesScore + uniqueSoftBouncesScore + spamReportScore
      const averageScore = scoreSum / 5
      if (opensScore === 0 || clicksScore === 0 || hardBouncesScore === 0 || uniqueSoftBouncesScore === 0 || spamReportScore === 0) {
        return Math.min(averageScore, 6)
      }

      return Number(averageScore.toFixed(1))
    }
    return 1
  }

  private categorizeScore = (score: number) => {
    if (score <= INITIALIZED_SCORE_GLOBAL.poor) {
      return PROGRESS_STATUS.POOR
    } else if (score <= INITIALIZED_SCORE_GLOBAL.needs_work) {
      return PROGRESS_STATUS.NEEDS_WORK
    } else if (score <= INITIALIZED_SCORE_GLOBAL.okay) {
      return PROGRESS_STATUS.OKAY
    } else if (score <= INITIALIZED_SCORE_GLOBAL.good) {
      return PROGRESS_STATUS.GOOD
    } else if (score <= INITIALIZED_SCORE_GLOBAL.very_good) {
      return PROGRESS_STATUS.VERY_GOOD
    } else {
      return PROGRESS_STATUS.EXCELLENT
    }
  }

  private getMetricScore = (
    metricValue: number,
    threshold: ProgressThreshold,
    comparisonOperator: Constants.MetricScoreOperation
  ) => {
    const thresholds = [
      {min: threshold.excellent, score: INITIALIZED_SCORE_INDIVIDUAL.excellent},
      {min: threshold.very_good, score: INITIALIZED_SCORE_INDIVIDUAL.very_good},
      {min: threshold.good, score: INITIALIZED_SCORE_INDIVIDUAL.good},
      {min: threshold.okay, score: INITIALIZED_SCORE_INDIVIDUAL.okay},
      {min: threshold.needs_work, score: INITIALIZED_SCORE_INDIVIDUAL.needs_work},
      {min: threshold.poor, score: INITIALIZED_SCORE_INDIVIDUAL.poor}
    ]
    let result = thresholds.find(({min}) => metricValue <= min)

    if(comparisonOperator === Constants.MetricScoreOperation.GREATER) {
       result = thresholds.find(({min}) => metricValue >= min)
    }

    return result ? result.score : 0
  }

  private opensAndClicksTag = (number: number, rate: ProgressThreshold) => {
    const thresholds = [
      {status: PROGRESS_STATUS.EXCELLENT, value: rate.excellent},
      {status: PROGRESS_STATUS.VERY_GOOD, value: rate.very_good},
      {status: PROGRESS_STATUS.GOOD, value: rate.good},
      {status: PROGRESS_STATUS.OKAY, value: rate.okay},
      {status: PROGRESS_STATUS.NEEDS_WORK, value: rate.needs_work},
      {status: PROGRESS_STATUS.POOR, value: rate.poor}
    ];

    if (!rate) {
      return {
        title: PROGRESS_STATUS.POOR,
        subtitle: rate.poor,
        class: ''
      };
    }

    const result = thresholds.find(({value}) => number > value);

    if (result) {
      return {
        title: result.status,
        subtitle: result.value,
        class: '--al-success'
      };
    }

    return {
      title: PROGRESS_STATUS.POOR,
      subtitle: rate.poor,
      class: ''
    };
  }
}

class ProgressComponent extends EmailHealthComponent {
  render() {
    const {title, heading, tagTitle, tagSubtitle, tagClass} = this.props
    return (
      <li className='stw-list-item'>
        <div className='stw-column'>
          <h2 className='stw-title'>{title}</h2>
          <h3 className='stw-heading'>{heading}</h3>
          <div className={`stw-tag-wrapper ${tagClass}`}>
            <h5 className='stw-tag-title'>{tagTitle}</h5>
            <h6 className='stw-tag-subtitle'>Benchmark: {tagSubtitle}</h6>
          </div>
        </div>
      </li>
    )
  }
}

export default ProgressComponent
export const AnalyticsOpensClicksComponent: ConnectedComponentClass<ComponentType<OpensClicksClass>, Fields> = GenericRedux.registerNewComponent(
  OpensClicksClass, 'analytics_opensclicks', {})
